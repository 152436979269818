/*-- Dark Mode Switcher --*/
var themeSwitcher = document.querySelector('.theme--switcher input');
var currentTheme = localStorage.getItem('theme');
if (currentTheme) {
  document.documentElement.setAttribute('data-theme', currentTheme);
  if (currentTheme === 'light') {
    themeSwitcher.checked = true;
  }
}
function switchTheme(e) {
  if (e.target.checked) {
    document.documentElement.setAttribute('data-theme', 'dark');
    localStorage.setItem('theme', 'dark');
  } else {
    document.documentElement.setAttribute('data-theme', 'light');
    localStorage.setItem('theme', 'light');
  }
}
themeSwitcher.addEventListener('change', switchTheme, false);

/*-- Navigation --*/
document.addEventListener('DOMContentLoaded', () => {
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll('.navbar-burger'),
    0
  );
  $navbarBurgers.forEach(el => {
    el.addEventListener('click', () => {
      const target = el.dataset.target;
      const $target = document.getElementById(target);
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');
    });
  });
});
document.addEventListener("DOMContentLoaded", function() {
  var anchors = document.querySelectorAll('a.navbar-item');

  anchors.forEach(function(anchor) {
      var nextDiv = anchor.nextElementSibling;
      if (nextDiv && nextDiv.tagName.toLowerCase() === 'div' && nextDiv.classList.contains('navbar-dropdown')) {
          anchor.classList.remove('navbar-item');
          anchor.classList.add('navbar-link');
          var wrapperDiv = document.createElement('div');
          wrapperDiv.classList.add('navbar-item', 'has-dropdown', 'is-hoverable');
          anchor.parentNode.insertBefore(wrapperDiv, anchor);
          wrapperDiv.appendChild(anchor);
          wrapperDiv.appendChild(nextDiv);
      }
  });
});

/*-- Lazy Loading Images --*/
var lazy = document.getElementsByClassName('lazy');

for(var i=0; i<lazy.length; i++){
 lazy[i].src = lazy[i].getAttribute('data-src');
}

/*-- Cookies Banner --*/
const $body = document.querySelector('body');
$body.addEventListener('cookies:saved', function(event) {
  console.log('Saved cookie features:', event.detail);
});

/*-- Animations --*/
var observer = new IntersectionObserver(
  function (entries) {
    entries.forEach(function (entry) {
      var el = entry.target;
      if (entry.isIntersecting) {
        el.classList.add('animate');
        return;
      }
    });
  },
  {threshold: 0.2}
);
document.querySelectorAll('.animation').forEach(function (i) {
  if (i) {
    observer.observe(i);
  }
});

